<template>
    <div class="dashboard">


        <!-- Dashboard -->
        <main class="container main-content mt64">
            <div class="page-header">
                <h1 class="page-title title-xl mb8">Pronto, vamos criar sua conta.</h1>
                <p class="page-description title-sm light">Este processo ainda é <strong>manual</strong>, e pode levar algumas horas. <br>Vamos te avisar por e-mail quando estiver concluído.</p>
                <br>
                <p class="page-description title-sm light">‣ Nenhum valor foi cobrado da sua conta ainda.</p>
            </div>
        </main>   
    </div>
</template>

<script>
export default {




}
</script>

<style lang="sass">








</style>